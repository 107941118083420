import { getUser } from "./services";

export const TOKEN_KEY = "@mapaapp-Token";
export const UUID_KEY = "@mapaapp-uuid";
export const ORDER_KEY = "@mapaapp-orderId";
export const VIEW_BOOK = "@mapaapp-viewBook";
export const NAME_KEY = "@mapaapp-name";

export async function isAuthenticated() {
  return await getUser()
    .then(result => {
      if (result && result.data && result.data._id) {
        return true;
      } else {
        removeLocalStorage();
      }
    })
    .catch(e => {
      console.log(e);
      removeLocalStorage();
      return false;
    });
}
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const isViewBook = () => localStorage.getItem(VIEW_BOOK) === "true";
export const getUuid = () => localStorage.getItem(UUID_KEY);
export const getName = () => localStorage.getItem(NAME_KEY);
export const setUuid = token => {
  localStorage.setItem(UUID_KEY, token);
};

export const setOrderId = id => {
  localStorage.setItem(ORDER_KEY, id);
};

export const getOrderId = () => localStorage.getItem(ORDER_KEY);

export const login = (token, name) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(VIEW_BOOK, "false");
  localStorage.setItem(NAME_KEY, name);
};

export const setViewBook = value => {
  localStorage.setItem(VIEW_BOOK, value);
};

export const removeLocalStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(UUID_KEY);
  localStorage.removeItem(ORDER_KEY);
  localStorage.removeItem(VIEW_BOOK);
  localStorage.removeItem(NAME_KEY);
};

export const logout = () => {
  removeLocalStorage();
  window.location.href = process.env.REACT_APP_BASE_URL_HOME;
};

export const checkLogout401 = (error) => {
  if (error && error.toString().includes('code 401')) {
    logout();
  }

  if (error && error.toString().includes('code 404')) {
    window.location.href = process.env.REACT_APP_BASE_URL_HOME;
  }
};

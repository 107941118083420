import api from "./api";

export const serialize = (obj) => {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const serializeArray = (obj) => {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (Array.isArray(obj[p])) {
        for (let o in obj[p]) {
          str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][o]));
        }
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
  }
  return str.join("&");
}

export const getUser = () => {
  return Promise.resolve(api.post("/admin/user/me", {}));
};

export const getUsuariosMapaDaProva = (data) => {
  return Promise.resolve(api.get("/admin/usuarios-admin?" + serialize(data)));
};

export const getTokenUsuarioMapaDaProva = (id) => {
  return Promise.resolve(api.get("/admin/token-usuario/" + id));
};

export const getBancas = (data) => {
  return Promise.resolve(api.get("/admin/banca?" + serialize(data)));
};

export const storeBanca = (data, progress) => {
  return Promise.resolve(api.post("/admin/banca", data, progress));
};

export const getBanca = (id) => {
  return Promise.resolve(api.get("/admin/banca/" + id));
};

export const excluirBanca = (id) => {
  return Promise.resolve(api.delete("/admin/banca/" + id));
};

export const getInstituicoes = (data) => {
  return Promise.resolve(api.get("/admin/instituicao?" + serialize(data)));
};

export const storeInstituicao = (data, progress) => {
  return Promise.resolve(api.post("/admin/instituicao", data, progress));
};

export const getInstituicao = (id) => {
  return Promise.resolve(api.get("/admin/instituicao/" + id));
};

export const excluirInstituicao = (id) => {
  return Promise.resolve(api.delete("/admin/instituicao/" + id));
};

export const getFormacoes = (data) => {
  return Promise.resolve(api.get("/admin/formacao?" + serialize(data)));
};

export const storeFormacao = (data, progress) => {
  return Promise.resolve(api.post("/admin/formacao", data, progress));
};

export const getFormacao = (id) => {
  return Promise.resolve(api.get("/admin/formacao/" + id));
};

export const excluirFormacao = (id) => {
  return Promise.resolve(api.delete("/admin/formacao/" + id));
};

export const getCargos = (data) => {
  return Promise.resolve(api.get("/admin/cargo?" + serialize(data)));
};

export const storeCargo = (data, progress) => {
  return Promise.resolve(api.post("/admin/cargo", data, progress));
};

export const getCargo = (id) => {
  return Promise.resolve(api.get("/admin/cargo/" + id));
};

export const getCargoByConcurso = (id) => {
  return Promise.resolve(api.get("/admin/cargo-concurso/" + id));
};

export const excluirCargo = (id) => {
  return Promise.resolve(api.delete("/admin/cargo/" + id));
};

export const getCarreiras = (data) => {
  return Promise.resolve(api.get("/admin/carreira?" + serialize(data)));
};

export const storeCarreira = (data, progress) => {
  return Promise.resolve(api.post("/admin/carreira", data, progress));
};

export const getCarreira = (id) => {
  return Promise.resolve(api.get("/admin/carreira/" + id));
};

export const excluirCarreira = (id) => {
  return Promise.resolve(api.delete("/admin/carreira/" + id));
};

export const getConcursos = (data) => {
  return Promise.resolve(api.post("/admin/filtro-concurso", data));
};

export const storeConcurso = (data, progress) => {
  return Promise.resolve(api.post("/admin/concurso", data, progress));
};

export const getConcurso = (id) => {
  return Promise.resolve(api.get("/admin/concurso/" + id));
};

export const excluirConcurso = (id) => {
  return Promise.resolve(api.delete("/admin/concurso/" + id));
};

export const getProvas = (data) => {
  return Promise.resolve(api.get("/admin/prova?" + serialize(data)));
};

export const getFiltroProvas = (data) => {
  return Promise.resolve(api.get("/admin/prova/buscar?" + serialize(data)));
};

export const getFiltroInstituicoes = (data) => {
  return Promise.resolve(api.get("/admin/instituicoes/buscar?" + serialize(data)));
};

export const getAnosProva = (data) => {
  return Promise.resolve(api.get("/admin/prova-anos?" + serialize(data)));
};

export const getAssuntosPai = (data) => {
  return Promise.resolve(api.get("/admin/prova-materias?" + serialize(data)));
};

export const getQuestoesProva = (id) => {
  return Promise.resolve(api.get("/admin/prova-questoes/" + id));
};

export const storeAtribuirQuestoesProvas = (data) => {
  return Promise.resolve(api.post("/admin/prova-atribuir-materia", data));
};

export const updateStatusProva = (id, status) => {
  return Promise.resolve(api.post("/admin/prova/update-status/" + id, status));
};

export const filtroProvas = (data) => {
  return Promise.resolve(api.get("/admin/filtro-prova?" + serialize(data)));
};

export const uploadImagemProvas = (data) => {
  return Promise.resolve(api.post("/admin/prova-upload-imagem", data));
};

export const uploadImagemComentarios = (data) => {
  return Promise.resolve(api.post("/admin/lote/upload-imagem", data));
};

export const storeProva = (data, progress) => {
  return Promise.resolve(api.post("/admin/prova", data, progress));
};

export const postBackupEstruturacaoProva = (id, data) => {
  return Promise.resolve(api.post("/admin/prova/backup_estruturacao/" + id, data));
};

export const getProva = (id) => {
  return Promise.resolve(api.get("/admin/prova/" + id));
};

export const excluirProva = (id) => {
  return Promise.resolve(api.delete("/admin/prova/" + id));
};

export const getMapas = (data) => {
  return Promise.resolve(api.get("/admin/mapa?" + serialize(data)));
};

export const excluirMapa = (id) => {
  return Promise.resolve(api.delete("/admin/mapa/" + id));
};

export const getQuestoes = (data) => {
  return Promise.resolve(api.post("/admin/questao/filtro-questao", data));
};
export const getQuestoesReportadas = (data) => {
  return Promise.resolve(api.get("/admin/questao/fila-denuncias?" + serialize(data)));
};

export const getComentariosReportados = (data) => {
  return Promise.resolve(api.get("/admin/comentario/fila-denuncias?" + serialize(data)));
};

export const getDenuncias = (id) => {
  return Promise.resolve(api.get("/admin/questao/denuncias/" + id));
};

export const postResolverProblema = (data) => {
  return Promise.resolve(api.post("/admin/questao/resolver-problema", data));
};

export const updateQuestaoProva = (data) => {
  return Promise.resolve(api.post("/admin/questao/update-questao", data));
};

export const updateGabaritoProva = (data) => {
  return Promise.resolve(api.post("/admin/questao/update-gabarito", data));
};

export const storeProvaQuestoes = (data, idProva, ultimoNumeroDaQuestao) => {
  return Promise.resolve(api.post("/admin/questao", data, idProva, ultimoNumeroDaQuestao));
};

export const getQuestoesByCargo = (data) => {
  return Promise.resolve(api.get("/admin/questao-cargo?" + serialize(data)));
};

export const getUsuarios = (data) => {
  return Promise.resolve(api.get("/admin/usuario?" + serialize(data)));
};

export const excluirArquivo = (id) => {
  return Promise.resolve(api.delete("/admin/arquivo/" + id));
};

export const salvarArquivo = (id, data, progress) => {
  return Promise.resolve(api.post("/admin/prova/" + id + '/arquivo', data, progress));
};

export const getMapa = (id) => {
  return Promise.resolve(api.get("/admin/mapa/" + id));
};

export const getUltimaProvaId = (id) => {
  return Promise.resolve(api.get("/admin/prova/ultima-prova/" + id));
};

export const storeMapa = (data) => {
  return Promise.resolve(api.post("/admin/mapa", data));
};
export const updateMapa = (data) => {
  return Promise.resolve(api.post("/admin/update-mapa", data));
};

export const putMapa = (data) => {
  return Promise.resolve(api.put("/admin/mapa", data));
};

export const filtroLotes = (data) => {
  return Promise.resolve(api.get("/admin/lote/filtro?" + serialize(data)));
};

export const getLote = (id) => {
  return Promise.resolve(api.get("/admin/lote/" + id));
};

export const excluirLote = (id) => {
  return Promise.resolve(api.delete("/admin/lote/" + id));
};

export const putLote = (data) => {
  return Promise.resolve(api.put("/admin/lote", data));
};

export const postRejeitarLote = (data) => {
  return Promise.resolve(api.post("/admin/lote/rejeitar", data));
};

export const getProfessores = (data) => {
  return Promise.resolve(api.get("/admin/professores?" + serialize(data)));
};

export const getProvasClassificacao = data => {
  return Promise.resolve(api.get("/admin/prova/classificacao?" + serialize(data)));
}

export const getQuestaoPorId = (id) => {
  return Promise.resolve(api.get("/admin/questao/" + id));
}

export const getQuestaoTecPorId = (id) => {
  return Promise.resolve(api.get("/admin/questao-tec/" + id));
}

export const getLoteQuestaoId = (data) => {
  return Promise.resolve(api.get("/admin/lote/questao/add?" + serialize(data)));
};

export const reloadQuestao = (id) => {
  return Promise.resolve(api.get("/admin/questao/reload?id=" + id));
}

export const getProvasComentarios = data => {
  return Promise.resolve(api.get("/admin/prova/comentarios?" + serialize(data)));
}

export const getAssuntos = (data) => {
  return Promise.resolve(api.get("/admin/assunto-lote?" + serialize(data)));
};
export const getMaterias = (data) => {
  return Promise.resolve(api.get("/admin/assuntos-materias" + serialize(data)));
};

export const getBancasDeMateriaClassificacao = (data) => {
  return Promise.resolve(api.get("/admin/prova/filtro/banca/classificacao?" + serialize(data)));
};

export const getInstituicaoDeMateriaClassificacao = (data) => {
  return Promise.resolve(api.get("/admin/prova/filtro/instituicao/classificacao?" + serialize(data)));
};

export const getAssuntosDeMateriaClassificacao = (data) => {
  return Promise.resolve(api.get("/admin/prova/filtro/assuntos/classificacao?" + serialize(data)));
};

export const postCadastrarLote = (data) => {
  return Promise.resolve(api.post("/admin/lote", data));
};

export const postCadastrarLoteQuestao = (data) => {
  return Promise.resolve(api.post("/admin/lote/questao", data));
};

export const postComentarIALote = (data) => {
  return Promise.resolve(api.post("/admin/lote/comentar-ia/", data));
};

export const postReprocessarQuestaoIALote = (data) => {
  return Promise.resolve(api.post("/admin/lote/questao/reprocessar-ia/", data));
};

export const getUsuarioPlano = (id) => {
  return Promise.resolve(api.get("/admin/usuario/plano/" + id));
};

export const cancelarUsuarioPlano = (data) => {
  return Promise.resolve(api.post("/admin/usuario/cancelar-plano", data));
};

export const caonfirmarPagamentoPlano = (data) => {
  return Promise.resolve(api.post("/admin/usuario/confirmar-pagamento-plano", data));
};

export const alterarProfessor = (data) => {
  return Promise.resolve(api.post("/admin/lote/alterar-professor", data));
};

export const enviarEmailNotificacao = (data) => {
  return Promise.resolve(api.post("/admin/lote/email-notificacao-professor", data));
};
// #### END-POINTS PROFESSOR ####

export const getQuestoesLote = (id) => {
  return Promise.resolve(api.get("/admin/lote/questoes/" + id));
};

export const getQuestoesClassificacao = (id) => {
  return Promise.resolve(api.get("/admin/classificacao/questoes/" + id));
};

export const getHistoricoQuestoes = (id) => {
  return Promise.resolve(api.get("/admin/historico/questoes/" + id));
};

export const getArquivosPdf = (id) => {
  return Promise.resolve(api.get("/admin/pdf/questoes/" + id));
};

export const postClassificarQuestao = (data) => {
  return Promise.resolve(api.post("/admin/questao/lote/classificar", data));
};

export const postComentarQuestao = (data) => {
  return Promise.resolve(api.post("/admin/questao/lote/comentar", data));
};


export const storeAssuntoSugerido = (data) => {
  return Promise.resolve(api.post("/admin/assunto/lote/classificacao/sugerir", data));
};

export const postMoverDeletarAssunto = (data) => {
  return Promise.resolve(api.post("/admin/assunto/lote/validacao/mover-deletar", data));
};

export const postMoverAssuntoSugerido = (data) => {
  return Promise.resolve(api.post("/admin/assunto/lote/classificacao/mover", data));
};

export const getAssuntosSugeridos = (id) => {
  return Promise.resolve(api.get("/admin/assunto/lote/sugeridos/" + id));
};

export const postRelatarProblema = (data) => {
  return Promise.resolve(api.post("/admin/questao/relatar-problema", data));
};

export const getRelatorioVendas = (data) => {
  return Promise.resolve(api.get("/admin/vendas-relatorio?" + serialize(data)));
};

export const getRelatorioRecorrencias = (data) => {
  return Promise.resolve(api.get("/admin/recorrencias-relatorio?" + serialize(data)));
};

export const getComentario = (id) => {
  return Promise.resolve(api.get("/admin/comentario/" + id));
};

export const excluirComentario = (data) => {
  return Promise.resolve(api.delete("/admin/comentario?" + serialize(data)));
};
export const getQuestoesCentralComentarios = (data) => {
  return Promise.resolve(api.get("/admin/questoes/central-comentarios?" + serialize(data)));
};
export const getCursos = () => {
  return Promise.resolve(api.get("/admin/questoes-filtro-curso"));
};

export const getCadernos = (id) => {
  return Promise.resolve(api.get("/admin/questoes-filtro-caderno/" + id));
};

export const getComentariosByQuestao = (data) => {
  return Promise.resolve(api.get("/admin/comentarios/questao?" + serialize(data)));
};
export const validarComentario = (data) => {
  return Promise.resolve(api.post("/admin/comentarios/validar", data));
};

export const getQuestoesComentarioSolicitado = (data) => {
  return Promise.resolve(api.get("/admin/questoes-comentario-solicitado?" + serialize(data)));
};

export const getEmailsComentarioSolicitado = (id) => {
  return Promise.resolve(api.get("/admin/emails-comentario-solicitado/" + id));
};

export const getQuestoesPorMateria = (data) => {
  return Promise.resolve(api.get("/admin/questao-filtro-materia?" + serialize(data)));
};
export const getProfessor = (id) => {
  return Promise.resolve(api.get("/admin/professores-mapa/" + id));
};
export const storeProfessor = (data) => {
  return Promise.resolve(api.post("/admin/professores-mapa", data));
};

export const excluirComentarioQuestao = (data) => {
  return Promise.resolve(api.post("/admin/questao/apagar-comentario", data));
};

export const excluirClassificacaoQuestao = (data) => {
  return Promise.resolve(api.post("/admin/questao/apagar-classificacao", data));
};
export const desvincularLotesQuestao = (data) => {
  return Promise.resolve(api.post("/admin/questao/desvincular-lotes", data));
};
export const postSalvarStringAssunto = (data) => {
  return Promise.resolve(api.post("/admin/assunto/store-string-pesquisa", data));
};
export const getAssunto = (id) => {
  return Promise.resolve(api.get("/admin/assunto/" + id));
};
export const getReviewsMapas = (data) => {
  return Promise.resolve(api.get("/admin/mapa-reviews?" + serialize(data)));
};
export const postComentarQuestaoSemLote = (data) => {
  return Promise.resolve(api.post("/admin/questao/comentar-sem-lote", data));
};

export const getAssuntosMapa = (id) => {
  return Promise.resolve(api.get("/admin/assuntos-mapa/" + id));
};

export const postNovoAssuntoMapa = (data) => {
  return Promise.resolve(api.post("/admin/store-assuntos-mapa", data));
};

export const getAssuntosFilhosMapa = (id) => {
  return Promise.resolve(api.get("/admin/assuntos-filhos-mapa/" + id));
};

export const editAssuntoMapa = (data) => {
  return Promise.resolve(api.post("/admin/edit-assuntos-mapa", data));
};
export const deleteAssuntoMapa = (data) => {
  return Promise.resolve(api.post("/admin/delete-assuntos-mapa", data));
};
export const getMapasComProvas = (data) => {
  return Promise.resolve(api.get("/admin/mapas-com-prova?" + serialize(data)));
};
export const importarAssuntosMapa = (data) => {
  return Promise.resolve(api.post("/admin/importar-assuntos-mapa", data));
};

export const getAllAssuntosMapa = (id) => {
  return Promise.resolve(api.get("/admin/all-assuntos-mapa/" + id));
}
export const associarAssuntos = (data) => {
  return Promise.resolve(api.post("/admin/associar-assuntos-mapa", data));
};
export const getHistoricoProva = (id) => {
  return Promise.resolve(api.get("/admin/prova-historico/" + id));
};

export const getProvasByConcurso = (id) => {
  return Promise.resolve(api.get("/admin/provas-concurso/" + id));
};

export const postSalvarProvasMapa = (data) => {
  return Promise.resolve(api.post("/admin/store-provas-mapa", data));
};

export const getProvasMapa = (id) => {
  return Promise.resolve(api.get("/admin/provas-mapa/" + id));
}

export const getContagemAssuntos = (id) => {
  return Promise.resolve(api.get("/admin/contagem-assuntos-mapa/" + id));
}

export const atualizarContagemQuestoesMapa = (data) => {
  return Promise.resolve(api.post("/admin/contagem-questoes-mapa", data));
};

export const getComentariosAlunos = (data) => {
  return Promise.resolve(api.get("/admin/comentarios-estudante?" + serialize(data)));
};

export const copiarProvasMapa = (data) => {
  return Promise.resolve(api.post("/admin/mapas-copiar-provas", data));
};

export const storeAssuntosEdital = (data) => {
  return Promise.resolve(api.post("/admin/store-assuntos-edital", data));
}

export const clonarMapa = (data) => {
  return Promise.resolve(api.post("/admin/mapa-clonar", data));
};

export const salvarGabaritoProva = (data) => {
  return Promise.resolve(api.post("/admin/prova-salvar-gabarito", data));
};

export const getAssuntoDesatualizacao = (id) => {
  return Promise.resolve(api.get("/admin/assunto-desatualizacao/" + id));
};

export const desatualizarAssunto = (data) => {
  return Promise.resolve(api.post("/admin/assunto-desatualizar-questoes", data));
};

export const updateDeadLineLote = (data) => {
  return Promise.resolve(api.post("/admin/lote/update-deadline", data));
};

export const getPlanos = () => {
  return Promise.resolve(api.get("/admin/planos-mapa"));
};

export const incidenciaProvas = data => {
  return Promise.resolve(api.get("/admin/prova-incidencia?" + serialize(data)));
}

export const getAllAssuntosFilhos = (data) => {
  return Promise.resolve(api.get("/admin/assunto-filhos?" + serialize(data)));
};

export const associarAssuntosCursoteca = (data) => {
  return Promise.resolve(api.post("/admin/assunto-cursoteca/associar", data));
};

export const getCadernosCurso = (data) => {
  return Promise.resolve(api.get("/admin/cadernos-curso?" + serialize(data)));
};

export const getProvasDuplicadas = (data) => {
  return Promise.resolve(api.get("/admin/provas-duplicadas?" + serialize(data)));
};

export const migrarQuestoesProva = (data) => {
  return Promise.resolve(api.post("/admin/provas/migrar-questoes-duplicadas", data));
};

export const migrarComentario = (data) => {
  return Promise.resolve(api.post("/admin/comentario/migrar", data));
};

export const getFilhosIncidencia = data => {
  return Promise.resolve(api.get("/admin/prova/filhos/incidencia?" + serialize(data)));
}

export const getHistoricoLog = (id) => {
  return Promise.resolve(api.get("/admin/historico-log/" + id));
};

export const getAssuntosEL = data => {
  return Promise.resolve(api.get("/admin/assunto?" + serialize(data)));
  // let cacheKey = '/admin/assunto?' + serialize(data);
  // return get(cacheKey);
}

export const getSimulados = (data) => {
  return Promise.resolve(api.get("/admin/caderno-simulado?" + serialize(data)));
};

export const getSimulado = (id) => {
  return Promise.resolve(api.get("/admin/caderno-simulado/" + id));
};

export const getQuestoesSelecionadas = (id) => {
  return Promise.resolve(api.get("/admin/caderno-simulado/questoes/" + id));
};

export const storeSimulado = (data) => {
  return Promise.resolve(api.post("/admin/caderno-simulado", data));
};

export const storeSimuladoQuestoes = (data) => {
  return Promise.resolve(api.post("/admin/caderno-simulado/questoes", data));
};

export const deleteSimulado = (id) => {
  return Promise.resolve(api.delete("/admin/caderno-simulado/" + id));
};
export const deleteModule = (id) => {
  return Promise.resolve(api.delete("/admin/caderno-simulado/modulo" + id));
};

export const getQuestoesFiltradas = data => {
  let cacheKey = '/questao?';
  return get(cacheKey, data);
}

export const getCountQuestoes = data => {
  let cacheKey = '/questao/count';
  return get(cacheKey, data);
}

export const getIncidencia = data => {
  let cacheKey = '/questao/incidencia';
  return get(cacheKey, data);
}

export const getAssuntosApi = data => {
  let cacheKey = '/assunto';
  return get(cacheKey, data);
}

export const get = async (url, data) => {
  let cacheKey = url + '?' + serialize(data);
  let response = sessionStorage.getItem(cacheKey);
  if (response) {
    return Promise.resolve(JSON.parse(response));
  } else {
    return Promise.resolve(api.post(url, data))
      .then(result => {
        try {
          sessionStorage.setItem(cacheKey, JSON.stringify(result));
        } catch (e) {
          console.error(e)
        }
        return Promise.resolve(result);
      }).catch(e => console.log(e));
  }
}

export const limparSessionStorage = () => {
  sessionStorage.clear();

  return true;
}




import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { getUser } from '../services/services';
import { checkLogout401, logout, getToken } from '../services/auth';

const AuthContext = createContext();

export function AuthContextProvider({ children }) {

  const [user, setUser] = useState(false);
  const [upGetUsuario, setUpGetUsuario] = useState();

  const [loadingUserContext, setLoadingUserContext] = useState(false);

  const isAuthenticated = !!user;

  const token = getToken();

  useEffect(() => {
    if (token) {
      setLoadingUserContext(true);
      getUser().then((response) => {
        if (response.data) {
          setUser(response.data);
        } else {
          logout();
        }
      }).catch(function (error) {
        checkLogout401(error);
      }).finally(() => {
        setLoadingUserContext(false);
      })
    }
  }, [upGetUsuario, token])

  // estado para atualizar useeffect e buscar novamente os dados do usuário
  function upUsuario() {
    setUpGetUsuario(!upGetUsuario);
  }

  return (
    <AuthContext.Provider value={{

      isAuthenticated,
      loadingUserContext,

      setUser: (state) => setUser(state),
      user,

      upUsuario
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext);
}

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';

import App from './App';
import { queryClient } from './services/queryClient';

import { icons } from './assets/icons';
import { Provider } from 'react-redux';
import store from './store';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://16b0ed2a987ca341017d83e7e5f5ab20@o4508251248001024.ingest.us.sentry.io/4508263626571776",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/admin\.mapadaprova\.com\.br/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

import React, { Component } from 'react';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import { AuthContextProvider } from './contexts/AuthContext';
import { getToken } from './services/auth';

import "react-toastify/dist/ReactToastify.css";
import 'react-select-search/style.css';
import '@coreui/icons/css/all.min.css';
import 'react-quill/dist/quill.snow.css';
import './styles/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Page1
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const PrivateRouter = ({ component: Component, ...rest }) => {

  const token = getToken();

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  )
}

class App extends Component {

  render() {
    return (
      <HashRouter>
        <ToastContainer />
        <React.Suspense fallback={loading}>
          <AuthContextProvider>
            <Switch>

              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <PrivateRouter path="/" component={props => <TheLayout {...props} />} />

            </Switch>
          </AuthContextProvider>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
